// @use '@angular/material' as mat;
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $app-primary: mat.define-palette(mat.$indigo-palette);
// $app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $app-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $app-theme: mat.define-light-theme((
//   color: (
//     primary: $app-primary,
//     accent: $app-accent,
//     warn: $app-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($app-theme);

// User variables
$header-height: 80px;
$footer-height: 100px;

$purple: #DA80F3;
$blue: #70A3F4;
$green: #64D0EA;

$facebook : #4267B2;
$twitter : #1C9CEA;
$youtube : #F70000;
$instagram : #EE752E;
$linkedin : #0073AF;
$medium : #FFC017;
$discord : #485EF4;
$twitch : #8C44F7;
$link : #808080;


/** SCSS Color Variables **/

:root {
	--ion-color-primary: #70A3F4;
	--ion-color-primary-rgb: 112,163,244;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #638fd7;
	--ion-color-primary-tint: #7eacf5;
	
	--ion-color-secondary: #DA80F3;
	--ion-color-secondary-rgb: 218,128,243;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #c071d6;
	--ion-color-secondary-tint: #de8df4;

	--ion-color-tertiary: #64D0EA;
	--ion-color-tertiary-rgb: 100,208,234;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #58b7ce;
	--ion-color-tertiary-tint: #74d5ec;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-dark: #090025;
	--ion-color-dark-rgb: 9,0,37;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #080021;
	--ion-color-dark-tint: #221a3b;

	--ion-color-medium: #7C8DB0;
	--ion-color-medium-rgb: 124,141,176;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #6d7c9b;
	--ion-color-medium-tint: #8998b8;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	// User defined
	// --text-color: #090025;
	// --text-color-2: rgb(0,0,0,.6);
	// --text-color-3: rgb(0,0,0,.3);
	--text-color: #222;
	--text-color-1: #253985;
	--text-color-invert: #FFF;
	--text-color-2: rgb(0,0,0,.6);
	--text-color-3: rgb(0,0,0,.4);
	--menu-text-color: rgba(0,0,0, 0.4);
	// --header-color: #FFF;
	--header-color: #FFF;
	// --header-color: #FEEDFC;
	--header-color-2: #eee;
	
	// --background-color: #F8F8F8;
	--background-color: linear-gradient(180deg, #FFF 0%, #FFEEFC 15%, #E3D5F9 52.6%, #C5DAFB 100%);
	--background-color-2: #FFF;
	--background-color-3: #f6f6f6;
	--background-color-primary-fade: #E9E5F9;
	--input-background: rgb(253, 253, 253, .1);
	
	--dark-icon: #ddd;
	--item-background: rgb(255,255,255,.2);
	// --item-background: white;
	--item-shadow: 0px 0px 15px rgba(0,0,0, 0.15);
	--item-shadow-2: 0px 0px 20px rgba(0,0,0, 0.05);
	--item-shadow-hover: 0px 10px 25px rgba(0,0,0, 0.15);
	--light-border: rgb(0,0,0,.1);
	--lightest-border: rgb(0,0,0,.075);

	--color-dark-blue: #190061;
	--color-grey: #2B303E;

	// --card-background: rgba(255,255,255);
	--card-background: rgba(255,255,255,.4);
	--card-background-hover: rgba(255,255,255,.5);
	--card-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
	--card-shadow-hover: 0px 10px 25px rgba(0,0,0, 0.15);

	--gradient-hover: linear-gradient(to right, #64D0EA, #70A3F4 5%,  #DA80F3 50%, #090025 50%);
	--gradient-hover-2: linear-gradient(to right, #64D0EA, #70A3F4 5%,  #DA80F3 50%, #DA80F3 50%);

	--scrollbar-color: #fff;
	--skeleton-background-rgb: 239,235,255;

	--see-more: #C8D9FB;

	--primary-gradient: linear-gradient(135deg, #C850E7 0%, #B6A3E1 51.78%, #9EC3F8 100%);
	--primary-gradient-hover: #C850E7;
	--light-fade: #FFF;

	--base : black;
	--button-border: #C850E7;

	--modal-background: linear-gradient(180deg, #FFF 0%, #FFEEFC 15%, #E3D5F9 52.6%, #C5DAFB 100%);
}
/** Dark Colors **/
body.dark {
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

	// User defined
	--text-color: #FFF;
	--text-color-1: #FFF;
	--text-color-invert: #253985;
	--text-color-2: rgb(255,255,255,.6);
	--text-color-3: rgb(255,255,255,.4);
	--menu-text-color: rgba(255, 255, 255, 0.5);
	// --header-color: #090025;
	--header-color: #1D274E;
	--header-color-2: #151d3b;

	// --background-color: #090025;
	--background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #243162 0%, #2A4DD0 52.34%, #794283 100%);;
	--background-color-2: #090025;
	--background-color-3: #12063B;
	--background-color-primary-fade: #12063B;
	--input-background: rgb(0,0,0,.1);

	--dark-icon: rgba(255, 255, 255, 0.3);
	--item-background: rgba(255, 255, 255, 0.2);
	--item-shadow: 0px 0px 30px rgba(255, 255, 255, 0.2);
	--item-shadow-2: 0px 0px 30px rgba(255, 255, 255, 0.08);
	--item-shadow-hover: 0px 0px 20px rgba(255, 255, 255, 0.3);
	--light-border: rgb(255,255,255, .15);
	--lightest-border: rgb(255,255,255, .1);

	--card-background: rgba(255, 255, 255, 0.1);
	--card-background-hover: rgba(255, 255, 255, 0.15);
	--card-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
	--card-shadow-hover: 0px 4px 10px 4px rgba(33, 210, 254, .2);

	--gradient-hover: linear-gradient(to right, #DA80F3 , #70A3F4 35%, #64D0EA 50%, #FFF 50%);
	--gradient-hover-2: linear-gradient(to right, #64D0EA, #70A3F4 5%,  #DA80F3 50%, #DA80F3 50%);
	// --gradient-hover-2: linear-gradient(to right, #DA80F3 , #70A3F4 35%, #64D0EA 50%, #DA80F3 50%);

	--scrollbar-color: #fff;
	--skeleton-background-rgb: 18,6,59;

	--see-more: #5A366F;

	--primary-gradient: linear-gradient(135.97deg, #FFD1F3 -2.81%, #D0E0FF 100%);
	--primary-gradient-hover: #FFD1F3;
	--light-fade: #684A80;

	--base : white;
	--button-border: #FFD1F3;

	// --modal-background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #243162 0%, #2A4DD0 52.34%, #794283 100%);
	--modal-background: linear-gradient(180deg, #FFF 0%, #FFEEFC 15%, #E3D5F9 52.6%, #C5DAFB 100%);
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


// /**
// * Generated theme by Material Theme Generator
// * https://materialtheme.arcsine.dev
// * Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzYxMzlmZiIsIj9lcjwjZDBjNGZmIiwiO2VyPCM0NTI0ZmZ$LCIlPmBePCMyMWQyZmUiLCI~ZXI8I2JjZjJmZiIsIjtlcjwjMTRjMWZlfiwid2Fybj5gXjwjZWI0NDVhIiwiP2VyPCNmOWM3Y2UiLCI7ZXI8I2UyMmQzZn4sIj9UZXh0PCMwOTAwMjUiLCI~PTwjZjhmOGY4IiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMwOTAwMjV$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxPdXRsaW5lZCIsIj9uZXNzPnRydWUsInZlcnNpb24$MTF9
// */
// @import '~@angular/material/theming';
// @import 'https://fonts.googleapis.com/icon?family=Material+Icons+Outlined';
     
// // Foreground Elements

// // Light Theme Text
// $dark-text: #090025;
// $dark-primary-text: rgba($dark-text, 0.87);
// $dark-accent-text: rgba($dark-primary-text, 0.54);
// $dark-disabled-text: rgba($dark-primary-text, 0.38);
// $dark-dividers: rgba($dark-primary-text, 0.12);
// $dark-focused: rgba($dark-primary-text, 0.12);

// $mat-light-theme-foreground: (
//   base:              black,
//   divider:           $dark-dividers,
//   dividers:          $dark-dividers,
//   disabled:          $dark-disabled-text,
//   disabled-button:   rgba($dark-text, 0.26),
//   disabled-text:     $dark-disabled-text,
//   elevation:         black,
//   secondary-text:    $dark-accent-text,
//   hint-text:         $dark-disabled-text,
//   accent-text:       $dark-accent-text,
//   icon:              $dark-accent-text,
//   icons:             $dark-accent-text,
//   text:              $dark-primary-text,
//   slider-min:        $dark-primary-text,
//   slider-off:        rgba($dark-text, 0.26),
//   slider-off-active: $dark-disabled-text,
// );

// // Dark Theme text
// $light-text: #ffffff;
// $light-primary-text: $light-text;
// $light-accent-text: rgba($light-primary-text, 0.7);
// $light-disabled-text: rgba($light-primary-text, 0.5);
// $light-dividers: rgba($light-primary-text, 0.12);
// $light-focused: rgba($light-primary-text, 0.12);

// $mat-dark-theme-foreground: (
//   base:              $light-text,
//   divider:           $light-dividers,
//   dividers:          $light-dividers,
//   disabled:          $light-disabled-text,
//   disabled-button:   rgba($light-text, 0.3),
//   disabled-text:     $light-disabled-text,
//   elevation:         black,
//   hint-text:         $light-disabled-text,
//   secondary-text:    $light-accent-text,
//   accent-text:       $light-accent-text,
//   icon:              $light-text,
//   icons:             $light-text,
//   text:              $light-text,
//   slider-min:        $light-text,
//   slider-off:        rgba($light-text, 0.3),
//   slider-off-active: rgba($light-text, 0.3),
// );

// // Background config
// // Light bg
// $light-background:    #f8f8f8;
// $light-bg-darker-5:   darken($light-background, 5%);
// $light-bg-darker-10:  darken($light-background, 10%);
// $light-bg-darker-20:  darken($light-background, 20%);
// $light-bg-darker-30:  darken($light-background, 30%);
// $light-bg-lighter-5:  lighten($light-background, 5%);
// $dark-bg-tooltip:     lighten(#090025, 20%);
// $dark-bg-alpha-4:     rgba(#090025, 0.04);
// $dark-bg-alpha-12:    rgba(#090025, 0.12);

// $mat-light-theme-background: (
//   background:               $light-background,
//   status-bar:               $light-bg-darker-20,
//   app-bar:                  $light-bg-darker-5,
//   hover:                    $dark-bg-alpha-4,
//   card:                     $light-bg-lighter-5,
//   dialog:                   $light-bg-lighter-5,
//   tooltip:                  $dark-bg-tooltip,
//   disabled-button:          $dark-bg-alpha-12,
//   raised-button:            $light-bg-lighter-5,
//   focused-button:           $dark-focused,
//   selected-button:          $light-bg-darker-20,
//   selected-disabled-button: $light-bg-darker-30,
//   disabled-button-toggle:   $light-bg-darker-10,
//   unselected-chip:          $light-bg-darker-10,
//   disabled-list-option:     $light-bg-darker-10,
// );

// // Dark bg
// $dark-background:     #090025;
// $dark-bg-lighter-5:   lighten($dark-background, 5%);
// $dark-bg-lighter-10:  lighten($dark-background, 10%);
// $dark-bg-lighter-20:  lighten($dark-background, 20%);
// $dark-bg-lighter-30:  lighten($dark-background, 30%);
// $light-bg-alpha-4:    rgba(#f8f8f8, 0.04);
// $light-bg-alpha-12:   rgba(#f8f8f8, 0.12);

// // Background palette for dark themes.
// $mat-dark-theme-background: (
//   background:               $dark-background,
//   status-bar:               $dark-bg-lighter-20,
//   app-bar:                  $dark-bg-lighter-5,
//   hover:                    $light-bg-alpha-4,
//   card:                     $dark-bg-lighter-5,
//   dialog:                   $dark-bg-lighter-5,
//   tooltip:                  $dark-bg-lighter-20,
//   disabled-button:          $light-bg-alpha-12,
//   raised-button:            $dark-bg-lighter-5,
//   focused-button:           $light-focused,
//   selected-button:          $dark-bg-lighter-20,
//   selected-disabled-button: $dark-bg-lighter-30,
//   disabled-button-toggle:   $dark-bg-lighter-10,
//   unselected-chip:          $dark-bg-lighter-20,
//   disabled-list-option:     $dark-bg-lighter-10,
// );

// // Compute font config
// // @include mat-core($fontConfig);

// // Theme Config

// body {
//   --primary-color: #DA80F3;
//   --primary-lighter-color: #d0c4ff;
//   --primary-darker-color: #4524ff;
//   --text-primary-color: #{$light-primary-text};
//   --text-primary-lighter-color: #{$dark-primary-text};
//   --text-primary-darker-color: #{$light-primary-text};
// }   
// $mat-primary: (
//   main: #DA80F3,
//   lighter: #d0c4ff,
//   darker: #4524ff,
//   200: #DA80F3, // For slide toggle,
//   contrast : (
//     main: $light-primary-text,
//     lighter: $dark-primary-text,
//     darker: $light-primary-text,
//   )
// );
// $theme-primary: mat-palette($mat-primary, main, lighter, darker);


// body {
//   --accent-color: #70A3F4;
//   --accent-lighter-color: #bcf2ff;
//   --accent-darker-color: #14c1fe;
//   --text-accent-color: #{$dark-primary-text};
//   --text-accent-lighter-color: #{$dark-primary-text};
//   --text-accent-darker-color: #{$dark-primary-text};
// }   
// $mat-accent: (
//   main: #70A3F4,
//   lighter: #bcf2ff,
//   darker: #14c1fe,
//   200: #70A3F4, // For slide toggle,
//   contrast : (
//     main: $dark-primary-text,
//     lighter: $dark-primary-text,
//     darker: $dark-primary-text,
//   )
// );
// $theme-accent: mat-palette($mat-accent, main, lighter, darker);


// body {
//   --warn-color: #eb445a;
//   --warn-lighter-color: #f9c7ce;
//   --warn-darker-color: #e22d3f;
//   --text-warn-color: #{$light-primary-text};
//   --text-warn-lighter-color: #{$dark-primary-text};
//   --text-warn-darker-color: #{$light-primary-text};
// }   
// $mat-warn: (
//   main: #eb445a,
//   lighter: #f9c7ce,
//   darker: #e22d3f,
//   200: #eb445a, // For slide toggle,
//   contrast : (
//     main: $light-primary-text,
//     lighter: $dark-primary-text,
//     darker: $light-primary-text,
//   )
// );
// $theme-warn: mat-palette($mat-warn, main, lighter, darker);
// ;

// $theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
// $altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// // Theme Init
// @include angular-material-theme($theme);

// .theme-alternate {
//   @include angular-material-theme($altTheme);
// }

// // Specific component overrides, pieces that are not in line with the general theming

// // Handle buttons appropriately, with respect to line-height
// // .mat-raised-button, .mat-stroked-button, .mat-flat-button {
// //   padding: 0 1.15em;
// //   margin: 0 .65em;
// //   min-width: 3em;
// //   line-height: 36.4px
// // }

// // .mat-standard-chip {
// //   padding: .5em .85em;
// //   min-height: 2.5em;
// // }

// // .material-icons {
// //   font-size: 24px;
// //   font-family: 'Material Icons Outlined', 'Material Icons';  
// //   .mat-badge-content {
// //     font-family: 'Roboto';
// //   }
// // }