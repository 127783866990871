// Ionic Styles
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Variables
@import "/src/theme/variables.scss";
@import '/src/theme/animations.scss';


// Main Styling
* {
    box-sizing: border-box;
    font-family:  montserrat, sans-serif;


    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar { display: none; }

    scrollbar-color: var(--lightest-border) rgb(0,0,0,0);
    scrollbar-width: thin;
}
html { 
    // height: 100%;
    // width: 100vw;
    scroll-behavior : smooth;
    // overflow: hidden;
    // z-index: 0;
    // background: var(--background-color) !important;

}
body {
    margin: 0;
    color: var(--text-color) !important;
    background: var(--background-color) !important;
    height: 100%;
    z-index: 1;
    transition: .1s;
}
ion-content {
    --background: var(--background-color) !important;
}
.ion-content {
    min-height: calc(100vh - $header-height - $footer-height - 5px)
}
@media (max-width: 991px){
    .container {
        padding: 0 2rem !important;    
    }
}
.background { background-color: var(--background-color) !important; }

// Fonts 
h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    color: var(--text-color);
    font-family:  montserrat, sans-serif;
    line-height: normal !important;
    font-weight: 600;
}
h1 {
    font-weight: 800 !important;
    font-size: 50px;
    line-height: 50px;
    text-shadow: 0px 4px 37px rgba(255, 255, 255, 0.5);
}
h2 {
    font-weight: 600 !important;
    font-size: 30px;
    line-height: 30px;
    text-shadow: 0px 4px 37px rgba(255, 255, 255, 0.5);
}
small {
    font-weight: 600;
    color: var(--text-color-3);
}
.my-small {
    font-size: 75%;
    font-weight: 600;
    color: var(--text-color-3);
}
p {
    color: var(--text-color-2);
}

.text-logo {
    font-size: 26px;
    font-weight: 900;
    cursor: pointer;
    color: var(--text-color-1);
    text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.5) !important;  
    transform: .2s;

    &:hover {
        color: var(--ion-color-primary);
        text-shadow: 0px 0px 40px rgba(255, 255, 255, .5) !important;
    }  
}
.ultrathin  { font-weight: 100; }
.thin       { font-weight: 200; }
.light      { font-weight: 300; }
.regular    { font-weight: 400; }
.medium     { font-weight: 500; }
.semibold   { font-weight: 600; }
.bold       { font-weight: 700; }
.extrabold  { font-weight: 800; }
.heavy      { font-weight: 900; }

// Colors 
.primary { color: var(--ion-color-primary) }
.gray-text { color: var(--menu-text-color) }
.text-color { color: var(--text-color); }
.text-color-1 { color: var(--text-color-1); }
.text-color-2 { color: var(--text-color-2); }
.text-color-3 { color: var(--text-color-3); }
.primary-hover:hover { color: var(--ion-color-primary) !important; cursor: pointer;  }
.purple-hover:hover { color: $purple !important; cursor: pointer;  }
.green { color: var(--ion-color-success) !important }
.red { color: var(--ion-color-danger) !important }
.purple { color: #952aa9 !important }

.facebook:hover, .facebook-active   { background-color: $facebook !important }
.twitter:hover, .twitter-active     { background-color: $twitter !important }
.youtube:hover, .youtube-active     { background-color: $youtube !important }
.instagram:hover, .instagram-active { background-color: $instagram !important }
.linkedin:hover, .linkedin-active   { background-color: $linkedin !important }
.medium:hover, .medium-active       { background-color: $medium !important }
.discord:hover, .discord-active     { background-color: $discord !important }
.twitch:hover, .twitch-active       { background-color: $twitch !important }
.link:hover, .link-active           { background-color: var(--ion-color-primary) !important }

.dark-icon i {
    color: var(--dark-icon);

    &:hover {
        color: var(--ion-color-primary)
    }
}

// Spacing
.flex-center { 
    display: flex; 
    align-items: center;
    justify-content: center;
}
.flex-between { 
    display: flex; 
    align-items: center;
    justify-content: space-between;
}
.responsive-flex-between {
    @media (min-width: 768px){
        display: flex; 
        align-items: center;
        justify-content: space-between;
    }
    @media (max-width: 767px){
        display: block !important;
    }
}
.flex-start, .flex-left { 
    display: flex; 
    align-items: center;
    justify-content: flex-start;
}
.flex-end, .flex-right { 
    display: flex; 
    align-items: center;
    justify-content: flex-end;
}
.block { display: block; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.margin-header {
    // margin-top: $header-height !important;
}
.pfp-xs {
    height: 30px;
    width: 30px;
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
}
.pfp-sm {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: top;
    border-radius: 100px;
}
.w-max-content { width: max-content }


.glow-1 {
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.25);
}
.text-glow {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}
.item-shadow {
    box-shadow: var(--item-shadow);
}
.pointer { cursor: pointer; }
.br-15 { border-radius: 15px !important; }

// Buttons
.btn-main {
    // margin: 0 1.5rem;
    width: max-content;
    height: 30px;
    // left: 5595px;
    // top: 4601px;
    padding: 20px 30px;

    color: var(--text-color-invert);
    background: var(--primary-gradient);
    // background: var(--ion-color-primary);
    // box-shadow: var(--item-shadow);
    border-radius: 25px;
    // border: 2px solid var(--button-border);

    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    // transition: .2s;
    cursor: pointer;
    z-index: 1000;
    position: relative;

    white-space: nowrap;
    text-align: center;

    &:hover {
        // background: rgb(0,0,0,0);
        background: var(--primary-gradient-hover);
        // color: var(--ion-color-primary);
        // box-shadow: var(--item-shadow-hover);

        &:not(.no-grow) {
            transform: scale(1.02);
        }
    }

    i {
        margin-right: 1rem;
    }

    &.disabled {
        pointer-events: none !important;
        opacity: .5 !important;
        // filter: grayscale(.8);
    }
}
.btn-outline {
    // margin: 0 1.5rem;
    width: max-content;
    height: 30px;
    // left: 5595px;
    // top: 4601px;
    padding: 18px 30px;

    color: var(--button-border);
    background: rgb(0,0,0,0);
    // box-shadow: var(--item-shadow);
    border-radius: 25px;
    border: 2px solid var(--button-border);

    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 14px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    transition: .2s;
    cursor: pointer;
    z-index: 1000;
    position: relative;

    white-space: nowrap;
    text-align: center;

    &:hover {
        background: var(--primary-gradient);
        // color: white;
        color: var(--text-color-invert);

        // box-shadow: var(--item-shadow-hover);
        // transform: scale(1.03);

        &:not(.no-grow) {
            transform: scale(1.03);
        }
    }

    i {
        margin-right: 1rem;
    }
}
.dual-button {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-main {
        width: 100%;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-right: 0 !important;

        &:hover { transform: none !important }
    }
    .btn-outline {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &:hover { transform: none !important }
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
}
.arrow-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: .5;
    transition: .2s;
    color: var(--menu-text-color);
    margin-top: .5rem;
    margin-bottom: .5rem;
    
    span {
        text-transform: uppercase;
        font-weight: 500;
    }
    &.right {
        span {
            margin-right: .5rem;
        }
    }

    i {
        transition: .2s;;
        color: var(--menu-text-color);
    }

    &:hover {
        opacity: 1;
        color: var(--ion-color-primary);

        i {
            color: var(--ion-color-primary);
        }

        &.right {
            i {
                transform: translateX(10px);
            }
        }
    }
}

// Ionic overrides
// ion-segment {
//     ion-segment-button {
//         ion-label {

//         }
//     }
// }

ion-segment-button {
    // min-width: 0 !important;

    ion-label {
        color: var(--menu-text-color);
    }
}
ion-segment-button.segment-button-checked {
    ion-label {
        color: var(--ion-color-primary);
    }
}
ion-label {
    text-transform: initial !important;
}
.segment-border-bottom {
    border-bottom: 1px solid var(--light-border);
    ion-segment { margin-bottom: -1.5px; }
}
.my-border-bottom {
    border-bottom: 1px solid var(--lightest-border);
    // margin-bottom: .5rem;

    &:last-child {
        border-bottom: none !important;
        margin-bottom: 0 !important
    }
}

#filters {
    ion-item.item-label {
        --background: rgb(0,0,0,0);
        --color: white;
        --padding-start: 1rem;
        --border-radius: 15px;
        
        ion-checkbox {
            margin: 0 !important;
        }
        
        ion-label {
            font-family:  montserrat, sans-serif !important;
            font-size: 18px !important;
            font-weight: 300 !important;
            margin-left: 1rem !important;
            color: var(--menu-text-color) !important;
            transition: .2s;
        }
        &:hover {
            ion-label {
                color: var(--text-color) !important;
            }
        }
    }
}
ion-item {
    --background: rgb(0,0,0,0) !important;
    --highlight-color-valid: var(--ion-color-primary);
    --border-color: var(--light-border) !important;
}
ion-item.item-label {
    --background: rgb(0,0,0,0);
    --color: var(--text-color);
    
    ion-label {
        font-family:  montserrat, sans-serif !important;
        transition: .2s;
        color: var(--menu-text-color) !important;
    }
}
ion-item.no-pad {
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --inline-padding-start: 0px !important;
    --inline-padding-end: 0px !important;
}
ion-item.item-checkbox-checked {
    ion-label {
        color: var(--text-color) !important;
    }
}
ion-item.quiz {
    --inner-padding-bottom: 0px !important;
    --inner-padding-top: 0px !important;
    --padding-bottom: 0px !important;
    --padding-top: 0px !important;
    --border-radius: 15px;
    --min-height: 10px
}
// .no-bg {
//     ion-item {

//         --background: rgb(0,0,0,0) !important;
//     }
// }
ion-checkbox {
    --border-color: var(--menu-text-color);
    --background: rgb(0,0,0,0)
}
ion-chip {
    --background: var(--light-border);
    --color: var(--text-color-2);
    transition: .2s;

    i {
        margin-right: .5rem;
    }

    &:hover, &.active {
        --background: var(--ion-color-primary);
        --color: white;
        // box-shadow: var(--card-shadow) !important;

    }

    &.primary-chip {
        --background: var(--ion-color-primary);
        --color: white;
    }
}
ion-footer {
    padding: 1rem;
    // padding: 1rem 1rem 1.5rem 1rem;
    background-color: var(--background-color) !important; 
    border-top: 1px solid rgb(172,172,172,.2);

    &.md {
        padding-bottom: 1rem;
    }
}
ion-modal {
    ion-content {
        --background: var(--modal-background) !important;
    }
}
ion-skeleton-text {
    --background-rgb: var(--ion-color-primary-rgb);
    margin: 0 !important;
    border-radius: 8px;
}
ion-segment.ios {
    --background: var(--lightest-border);
    width: max-content !important;

    ion-segment-button {
        max-width: max-content !important;
    }

}
ion-segment.ios {
    ion-segment-button.segment-button-checked {
        ion-label {

            // background-color: red !important;
        }
    }
}
ion-item {
    --border-color: var(--lightest-border) !important;

    i {
        color: var(--menu-text-color);
    }

    .icon-container {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
ion-header {
    --background: var(--header-color-2);

}
ion-toolbar {
    --background: var(--header-color-2);
    --color: var(--text-color);
    padding: 0 6px;
}
ion-footer {
    --background: var(--header-color-2);
    --color: var(--text-color)
}
ion-title {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-shadow: 0px 4px 37px rgba(255, 255, 255, 0.5);
}
.item-interactive-disabled {
    cursor: default;
    opacity: 1 !important;
    pointer-events:all !important;
}
.my-input {
    color: var(--text-color) !important;
    font-weight: 600;
}
// ion-alert {
//     --background: var(--input-background) !important;
//     // --color: var(--text-color) !important;

//     .alert-title {
//         color: var(--text-color) !important;
//     }
//     .alert-message {
//         color: var(--text-color-2) !important;
//     }
// }


// Scrollbar Styling
::-webkit-scrollbar {
    width: 5px;
    border: none !important;
}
::-webkit-scrollbar-button:single-button {
    background-color: rgb(0,0,0,0);
    height: 0;
    width: 0;
}
::-webkit-scrollbar-thumb {
    background: var(--lightest-border);
    border-radius: 50px;;
}
::-webkit-scrollbar-track {
    background: rgb(0,0,0,0);
}


body.dark {
    .sidebar-filter-container {
        background: rgb(0,0,0,0) !important;
        padding: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    // .filter:first-child {
    //     border-top: 1px solid var(--light-border);
    // }

    ion-modal {
        .filter:first-child {
            border-top: none !important;

            .filter-title {
                padding-top: 1.5rem !important;
            }
        }
    }
}



.light-box {
    background: var(--card-background);
    border: 2px solid rgb(0,0,0,0);
    box-shadow: var(--card-shadow);
    border-radius: 15px;
    padding: 1rem;
}
.primary-box {
    background: rgb(var(--ion-color-primary-rgb), .2);
    border: 2px solid rgb(0,0,0,0);
    // box-shadow: var(--card-shadow);
    border-radius: 15px;
    padding: 1rem;

}

.primary-section-1 {
    width: 100%;
    z-index: 3;
    position: relative;
    background-color: var(--background-color-2);
    
    .inner {
        background-color: rgb(var(--ion-color-primary-rgb), .1);

        .container {
            padding-top: 3rem !important;
            padding-bottom: 3rem !important;
        }
    }
}
.light-section-1 {
    width: 100%;
    z-index: 3;
    position: relative;
    // background-color: var(--background-color-3);
    
    .inner {
        // background-color: rgb(var(--ion-color-primary-rgb), .1);

        .container {
            padding-top: 3rem !important;
            padding-bottom: 2rem !important;
        }
    }
}

.separator {
    width: 100%;
    margin: 1rem 0rem;
    border-top: 1px solid var(--light-border);
}
.separator-lightest {
    width: 100%;
    margin: 1rem 0rem;
    border-top: 1px solid var(--lightest-border);
}

.square-icon {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-border);
    border-radius: 10px;
    padding: .5rem;

    i {
        color: var(--text-color-2);
    }

    &.left {
        margin-right: 1rem;
    }
}

ion-searchbar {
    padding: 0 !important;
}
.searchbar-input {
    background-color: var(--lightest-border) !important;
    color: var(--text-color) !important;
}

.negative-margin {
    margin: 0 -1rem !important;
    padding: 0 1rem !important;
}

.main-modal {
    // --height: 700px;
    --background: var(--modal-background);
    --border-radius: 10px;
    // --height: max-content;
    // .modal-wrapper {
    //     border-top-left-radius: 30px !important;
    //     border-top-right-radius: 30px !important;
    //     bottom: 0;
    //     position: inherit;
    //     // outline: 1px solid rgb(172,172,172,.2);
    // }
    // ion-header ion-toolbar:first-of-type {
    //     padding-top: 0px !important;
    // }
}

.video-modal {
    // --height: 100vh;
    // --height: calc(100vh - 80px);
    --width: 968px;
    --max-width: 100vw;
    --background: var(--header-color) !important;
}

ion-toast {

    .toast-header {
        font-weight: 600 !important;
    }
}

// ion-toast::part(button) {
//     // &.toast-button-icon-only {

//         height: auto;
//         width: auto;
//         font-size: 1.6em; // this sets actual icon size
//     // }
// }
input, textarea, ion-input {
    font-family:  montserrat, sans-serif !important;
    // font-weight: 600 !important;
}

form {
    * {
        transition: .1s;
    }

    .my-input {

        input[type=text], input[type=number] {
            padding: 10px;
            width: 100%;
            border-radius: 8px;
            margin: .5rem 0 0 0;
            background: var(--input-background);
            outline: 1px solid var(--light-border);
            border: none;
            color: var(--base);
        }

        textarea {
            width:100%;
            resize: vertical;
            padding:10px;
            border-radius: 8px;
            outline: 1px solid var(--light-border);
            background: var(--input-background);
            height:90px;
            border: none;
            margin: .5rem 0 0 0;
            color: var(--base);

        }

        *:focus {
            outline: 1px solid var(--ion-color-primary) !important;
        }

        *::placeholder {
            // font-weight: bold;
            // opacity: .5;
            color: var(--text-color-3);
        }
    }
}
.label {
    color: var(--text-color-2);
}

.my-border { border: 1px solid var(--light-border) }


ngx-dropzone {
    border: 2px dotted var(--light-border) !important;
    background: var(--input-background) !important;
    height: max-content !important;
    margin-top: .75rem;
    transition: .2s;
    border-radius: 10px !important;

    &:hover {
        border: 2px dotted var(--ion-color-primary) !important;

        ion-icon {
            color: var(--ion-color-primary);
            transform: scale(1.1);
        }
    }
}
ngx-dropzone-label {
    margin: 0 !important;
    text-align: left !important;
    width: 100% !important;
    overflow: hidden;

    .vid-container {
        height: 150px;
        margin-right: 1rem;
        border-radius: 5px;
        // width: 150px;

        video {
            height: 100%;
            border-radius: 5px;
        }

        ion-skeleton-text {
            height: 150px !important;
            width: 266.667px !important;
        }
    }
    .img-container {
        height: 150px;
        margin-right: 1rem;
        border-radius: 5px;
        // width: 150px;

        img {
            height: 150px;
        }
        ion-skeleton-text {
            height: 150px !important;
            width: 150px !important;
        }
    }

    ion-icon {
        font-size: 60px;
        color: var(--text-color-2);
        transition: .2s;
    }
}



.my-alert {
    background-color: rgb(var(--ion-color-primary-rgb), .2);
    border: 1px solid rgb(var(--ion-color-primary-rgb), .4);
    padding: .75rem 1rem;    
    border-radius: 15px;
    width: 100%;
    color: var(--text-color-2);
    margin-bottom: 1rem;

    &.success {
        background-color: rgb(var(--ion-color-success-rgb), .2);
        border: 1px solid rgb(var(--ion-color-success-rgb), .4);
    }
    &.danger {
        background-color: rgb(var(--ion-color-danger-rgb), .2);
        border: 1px solid rgb(var(--ion-color-danger-rgb), .4);
    }
    &.warning {
        background-color: rgb(var(--ion-color-warning-rgb), .2);
        border: 1px solid rgb(var(--ion-color-warning-rgb), .4);
    }
}
.my-alert-small {
    background-color: rgb(var(--ion-color-primary-rgb), .2);
    border: 1px solid rgb(var(--ion-color-primary-rgb), .4);
    padding: .1rem .5rem;    
    border-radius: 8px;
    // width: 100%;
    color: var(--text-color-2);
    // margin-right: 1rem;
    font-size: 80%;
    text-transform: uppercase;

    &.success {
        background-color: rgb(var(--ion-color-success-rgb), .2);
        border: 1px solid rgb(var(--ion-color-success-rgb), .4);
    }
    &.danger {
        background-color: rgb(var(--ion-color-danger-rgb), .2);
        border: 1px solid rgb(var(--ion-color-danger-rgb), .4);
    }
    &.warning {
        background-color: rgb(var(--ion-color-warning-rgb), .2);
        border: 1px solid rgb(var(--ion-color-warning-rgb), .4);
    }
}

.my-list {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;

    small { 
        opacity: .7 ;
        display: inline-block;
        white-space: nowrap;
        width: 100%;
    }

    i {
        width: 25px;
        opacity: .7;
        margin: .5rem;
        padding-left: 5px;
    }

    &:active, &:hover {
        background-color: rgb(var(--ion-color-dark-rgb), .1) !important;
        color: var(--ion-color-primary);
    }

    &.no-hover:hover, &.no-hover:active {
        background-color: unset!important;
    }
}
.options-popover {
    --backdrop-opacity : .3;
    --width: 185px;
    border: 1px solid rgb(127,127,127, .2);
}

.faded {
    opacity: .5 !important;
}

.approve {
    color: white;
    background-color: rgb(var(--ion-color-success-rgb), .7);
    border-radius: 100px;
    padding: .5rem;
    padding: 6px 14px;
    width: max-content;
    font-size: 80%;
    margin: 4px;
    font-weight: 600;
    cursor: pointer !important;
    transition: .2s;

    i { margin-right: 0.5rem; }

    &:hover {
        background-color: rgb(var(--ion-color-success-rgb), 1);
    }
}
.deny {
    color: white;
    background-color: rgb(var(--ion-color-danger-rgb), .7);
    border-radius: 100px;
    padding: .5rem;
    padding: 6px 14px;
    width: max-content;
    font-size: 80%;
    margin: 4px;
    font-weight: 600;
    cursor: pointer !important;
    transition: .2s;

    i { margin-right: 0.5rem; }

    &:hover {
        background-color: rgb(var(--ion-color-danger-rgb), 1);
    }
}

.fixed-top-right {
    position: fixed;
    top: 10px;
    right: 10px;
}